import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { SsrHelperService } from 'src/app/core/services/ssr-helper.service';

@Pipe({
  name: 'safeHtml',
  standalone: true,
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(
    private sanitizer: DomSanitizer,
    private ssrHelperService: SsrHelperService,
  ) {}

  transform(html: any): SafeHtml | string {
    if (!this.ssrHelperService.userAgent) {
      return '';
    }

    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
